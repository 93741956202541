<template>
  <div>
    <ToznyForm
      :error="errorMessage"
      :loading="loading"
      :onSubmit="submitOtp"
      class="login-form"
    >
      <ToznyInput
        v-model="otp"
        id="otp"
        name="otp"
        type="tel"
        title="Numbers only"
        pattern="[0-9]+"
        autocomplete="off"
        class="mb-4 md:text-lil"
        placeholder="123456"
        label="Authenticator Code"
        ref="totp"
        float-label
      />
      <ToznyButton
        id="login-button"
        class="login-button w-full py-3 rounded-lg text-lil"
        buttonText="Login"
        :loading="loading"
      />
    </ToznyForm>
    <div class="flex justify-between mb-10 text-tiny">
      <div class="flex-1">
        <a class="text-tozny text-tiny no-underline mr-4" href="login"
          >Back to login</a
        >
        <a class="text-tozny text-tiny no-underline" href="recover-mfa"
          >Reset MFA</a
        >
      </div>
      <a
        v-if="haveWebauthn"
        class="text-tozny text-tiny no-underline cursor-pointer"
        @click="switchMFA"
        >Login with Security Key</a
      >
    </div>
  </div>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'
import ToznyForm from '@/Common/ToznyForm'
import ToznyInput from '@/Common/ToznyInput'
import { mapState } from 'vuex'

export default {
  name: 'totp-form',
  components: {
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  props: {
    loginError: String,
    loading: Boolean,
    haveWebauthn: Boolean,
  },
  data: function () {
    return {
      otp: '',
      localError: '',
    }
  },
  computed: {
    ...mapState(['displayError']),
    errorMessage() {
      if (this.displayError) return this.displayError
      return this.loginError ? this.loginError : this.localError
    },
  },
  methods: {
    async submitOtp() {
      this.localError = ''
      const otp = this.otp.trim()
      if (otp) {
        this.$emit('totpSubmit', { totp: otp })
      } else {
        this.localError = 'Please enter an authentication code.'
      }
    },
    switchMFA() {
      this.$emit('switchMFA', 'webauthn')
    },
  },
  mounted() {
    // auto focus on the input element when the form loads.
    this.$refs.totp.$el.querySelector('#otp').focus()
  },
}
</script>

<template>
  <AuthLayout>
    <template #info>
      <InfoPanel title="Security. Privacy. Trust." subtitle="" />
    </template>
    <template #form>
      <h3 class="text-sm font-semibold mb-4">Sign in to Tozny</h3>
      <ToznyForm
        v-if="loginFlow === 'Password'"
        :success="
          paperKeySuccessMessageForLogin
            ? 'Login with your new password!'
            : null
        "
        :error="displayError"
        :loading="loading"
        :onSubmit="btnLogin"
        class="login-form"
      >
        <ToznyInput
          v-model="email"
          id="email"
          name="email"
          class="mb-6"
          placeholder="name@example.com"
          label="Email"
          float-label
        />
        <ToznyInput
          v-model="password"
          type="password"
          id="password"
          name="password"
          class="mb-6"
          label="Password"
          float-label
        />
        <div class="flex justify-between mb-12 text-tiny">
          <a
            href="#!"
            class="no-underline text-tozny"
            @click.prevent="routeToRecover"
            >Forgot Password?</a
          >
          <div v-if="!registrationDisabled" class="text-gray-dark">
            Need An Account?
            <a
              href="#!"
              class="no-underline text-tozny"
              @click.prevent="routeToRegister"
              >Sign Up</a
            >
          </div>
        </div>
        <ToznyButton
          id="login-button"
          class="login-button w-full py-4 rounded-lg"
          buttonText="Sign In"
          :loading="loading"
        />
      </ToznyForm>
      <TotpForm
        v-if="loginFlow === 'totp'"
        @totpSubmit="submitTotp"
        @switchMFA="switchMFA"
        :login-error="loginError"
        :loading="loadingTotp"
        :haveWebauthn="haveWebauthn"
      ></TotpForm>
      <WebauthnLogin
        v-if="loginFlow === 'webauthn'"
        @submitWebauthn="submitWebauthn"
        @switchMFA="switchMFA"
        :login-error="displayError"
        :loading="loading"
        :loginContext="loginContext"
        :haveTotp="haveTotp"
      >
      </WebauthnLogin>
    </template>
  </AuthLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AuthLayout from '../../Common/AuthLayout/AuthLayout'
import InfoPanel from '../../Common/AuthLayout/InfoPanel'
import ToznyButton from '../../Common/ToznyButton'
import ToznyForm from '../../Common/ToznyForm'
import ToznyInput from '../../Common/ToznyInput'
import TotpForm from './Components/TotpForm'
import WebauthnLogin from './Components/WebauthnLogin.vue'
export default {
  name: 'login',
  components: {
    AuthLayout,
    InfoPanel,
    ToznyForm,
    ToznyButton,
    ToznyInput,
    TotpForm,
    WebauthnLogin,
  },
  data: function () {
    return {
      email: '',
      password: '',
      loginError: '',
      loadingTotp: false,
      haveTotp: false,
      haveWebauthn: false,
    }
  },
  computed: {
    ...mapState(['displayError', 'loading', 'registrationDisabled']),
    ...mapState('account', [
      'paperKeySuccessMessageForLogin',
      'loginFlow',
      'loginContext',
      'mfaTypes',
    ]),
  },
  watch: {
    mfaTypes() {
      this.haveTotp = this.mfaTypes.indexOf('totp') > -1
      this.haveWebauthn = this.mfaTypes.indexOf('webauthn') > -1
    },
  },
  methods: {
    ...mapActions(['pushRoute']),
    // map `this.login()` to `this.$store.dispatch('login')`
    ...mapActions('account', [
      'login',
      'verifyTotp',
      'verifyWebAuthn',
      'changeLoginFlow',
    ]),
    async btnLogin() {
      const loggedIn = await this.login({
        password: this.password,
        email: this.email.toLowerCase(),
      })
      if (loggedIn) {
        this.pushRoute('/')
      }
    },
    routeToRegister() {
      this.pushRoute('/register')
    },
    routeToRecover() {
      this.pushRoute('/recover')
    },
    async submitTotp(totp) {
      try {
        this.loadingTotp = true
        const loggedIn = await this.verifyTotp(totp)
        this.loadingTotp = false
        if (loggedIn) {
          this.pushRoute('/')
        } else {
          console.log(loggedIn)
          this.loginError = loggedIn
        }
      } catch (e) {
        console.log(e)
      }
    },
    async submitWebauthn(payload) {
      try {
        const loggedIn = await this.verifyWebAuthn(payload)
        if (loggedIn) {
          this.pushRoute('/')
        } else {
          this.loginError = loggedIn
        }
      } catch (e) {
        console.log(e)
      }
    },
    switchMFA(type) {
      this.changeLoginFlow(type)
    },
  },
}
</script>

<template>
  <div>
    <h3 class="text-center">Security Key Authentication</h3>
    <div>
      <p class="text-left text-error text-center">{{ errorMessage }}</p>
    </div>
    <div>
      <p class="text-base">
        This account has a security key configured. Please authorize yourself
        with the key registered to your account.
      </p>
      <ToznyButton
        class="w-full py-3 rounded-lg text-lil"
        buttonText="Authorize"
        :loading="loading || authorizing"
        @click.native="attemptDeviceLogin"
      />
    </div>
    <div class="flex mb-10">
      <div class="flex-1">
        <a class="text-tozny text-tiny no-underline mr-4" href="login"
          >Back to login</a
        >

        <a class="text-tozny text-tiny no-underline" href="recover-mfa"
          >Reset MFA</a
        >
      </div>
      <a
        v-if="haveTotp"
        class="flex-end text-tozny text-tiny no-underline cursor-pointer"
        @click="switchMFA"
        >Login with Totp</a
      >
    </div>
  </div>
</template>

<script>
import ToznyButton from '@/Common/ToznyButton'
import { base64url } from 'rfc4648'
import { handleMFAError } from '@/utils/utils'

export default {
  name: 'webauthn-login',
  props: {
    loginContext: Object,
    loginError: String,
    loading: Boolean,
    haveTotp: Boolean,
  },
  data: () => ({
    localError: '',
    authorizing: false,
    abortController: new AbortController(),
  }),
  components: { ToznyButton },
  computed: {
    errorMessage() {
      return this.loginError ? this.loginError : this.localError
    },
  },
  methods: {
    /**
     * attempts to authenticate with registered webauthn device.
     * modified from https://github.com/keycloak/keycloak/blob/e239699b8199dc1997227c9caef15115db9eb8e5/themes/src/main/resources/theme/base/login/webauthn-authenticate.ftl#L32
     */
    async attemptDeviceLogin() {
      this.authorizing = true
      const isUserIdentified = this.loginContext.is_UserIdentified
      try {
        const allowedCredentials = isUserIdentified
          ? await this.getAllowedCredentials()
          : []
        await this.doAuthenticate(allowedCredentials)
      } catch (e) {
        this.handleError(e)
      } finally {
        this.authorizing = false
      }
    },
    /**
     * converts user's registered authenticators into credentials to use w/ navigator API
     * modified from https://github.com/keycloak/keycloak/blob/e239699b8199dc1997227c9caef15115db9eb8e5/themes/src/main/resources/theme/base/login/webauthn-authenticate.ftl#L41
     */
    async getAllowedCredentials() {
      const { authenticators } = this.loginContext
      let allowCredentials = []
      if (authenticators !== undefined) {
        allowCredentials.push({
          id: base64url.parse(authenticators.credential_id, {
            loose: true,
          }),
          type: 'public-key',
        })
      }
      return allowCredentials
    },
    /**
     * performs actual navigator authentication call & sends signed challenge to server.
     * modified from https://github.com/keycloak/keycloak/blob/e239699b8199dc1997227c9caef15115db9eb8e5/themes/src/main/resources/theme/base/login/webauthn-authenticate.ftl#L64
     */
    async doAuthenticate(allowCredentials) {
      const { challenge, rpId } = this.loginContext
      const userVerification = this.loginContext.user_Verification
      const publicKey = {
        rpId,
        challenge: base64url.parse(challenge, { loose: true }),
        // default to "discouraged" for better windows UX
        userVerification: 'discouraged',
      }

      if (allowCredentials.length) {
        publicKey.allowCredentials = allowCredentials
      }

      if (userVerification !== 'not specified') {
        publicKey.userVerification = userVerification
      }
      const result = await navigator.credentials.get({ publicKey })
      const payload = {
        clientDataJSON: this.b64urlEncode(result.response.clientDataJSON),
        authenticatorData: this.b64urlEncode(result.response.authenticatorData),
        signature: this.b64urlEncode(result.response.signature),
        credentialId: this.b64urlEncode(result.rawId),
        challenge: challenge,
      }
      // if (result.response.userHandle) {
      //   payload.userHandle = this.b64urlEncode(result.response.userHandle)
      // }

      this.$emit('submitWebauthn', payload)
    },
    handleError(e) {
      // eslint-disable-next-line no-console
      console.error(e)
      const message = (() => {
        switch (e.name) {
          case 'AbortError':
            return 'Security key authentication aborted.'
          case 'InvalidStateError':
            return 'Unexpected authentication error. Are you using the correct security key?'
          default:
            return handleMFAError(
              e,
              'Something went wrong authenticating your security key.'
            )
        }
      })()
      this.localError = message
    },
    b64urlEncode(str) {
      return base64url.stringify(new Uint8Array(str), { pad: false })
    },
    onKeyDown(e) {
      // hitting escape during the navigator API process is handled differently by different browsers.
      // in chrome, edge, & safari it immediately throws an error that is caught and we allow
      // the user to try again.
      // in firefox however, the escape has terrible behavior. pressing escape does not stop the
      // underlying process & prevents the user from trying again.
      // because of the error handling in this component, the code below only runs when the firefox-like
      // case occurs. if escaping aborts with no immediate error, this code is called and we just
      // redirect them back to the login page to prevent the user from being trapped.
      if (e.key == 'Escape' && this.authorizing) {
        this.authorizing = false
        // this is not good UX, but its better than an endless "you aborted" message from firefox...
        window.location.reload()
      }
    },
    switchMFA() {
      this.$emit('switchMFA', 'totp')
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
}
</script>
